import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
import EmptyComponent from '../../../../components/EmptyContent';
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
import { Box, Grid, IconButton, Menu, MenuItem, TextField, Typography } from '@material-ui/core';
// import { DataGrid, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { BASE_URL_SERVICE } from '../../../../constants';
import { DataGridPro, GridToolbarDensitySelector, GridToolbarFilterButton, useGridApiContext, useGridApiRef } from '@mui/x-data-grid-pro';
import useDataGridFilterPersist from '../../../../hooks/useDataGridFilterPersist';
import { getAlumnosSeguimiento } from '../../../../services/alumnos';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function AlumnosSeguimientoContent() {
    const classes = useStyles();

    const [Alumnos, setAlumnos] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const history = useHistory()

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando alumnos")

        const usuario_id = usuarioSeleccionado.id

        getAlumnosSeguimiento({ usuario_id }).then((response) => {

            response && setAlumnos(response.alumnos)
            setLoading(false)

        });

        // getComerciales({usuario_id: usuario_id}).then( (response) => {
        //     response.comerciales && setComerciales(response.comerciales)
        // })
    }, [])

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    const columnas = 
    [
      { field: 'id', hide: false, headerName: 'ID' },
      { field: 'nombre', headerName: 'Nombre', minWidth: 250, flex: 1 },
      { field: 'status_nombre', headerName: 'Estado',minWidth: 120 },
      { field: 'correo_electronico', headerName: 'Correo electronico', flex:1, minWidth: 200 },
      { field: 'comunidad_autonoma_nombre', headerName: 'Comunidad', minWidth: 150 },
      { field: 'especialidad_nombre', headerName: 'Especialidad', minWidth: 150 },
      { field: 'sum_notificaciones_no_leidas', headerName: 'Notificaciones pendientes', type:"number", minWidth: 150 },
    ]

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    function QuickSearchToolbar(props) {
        const classes = useStyles();
      
        return (
          <div className={classes.rootFilter}>
            <div>
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
            </div>
            <TextField
              variant="standard"
              value={props.value}
              onChange={props.onChange}
              placeholder="Buscar…"
              className={classes.textFieldFilter}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: props.value ? 'visible' : 'hidden' }}
                    onClick={props.clearSearch}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </div>
        );
      }
      
      QuickSearchToolbar.propTypes = {
        clearSearch: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
      };

    const Contenido = () => {
        const [rows, setRows] = React.useState(Alumnos);
        const [searchText, setSearchText] = React.useState('');
        const requestSearch = (searchValue) => {
          // console.log(searchValue)
          setSearchText(searchValue);
          const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
          // const searchRegex = new RegExp(`.*${searchValue}.*`, 'ig');
          const filteredRows = Alumnos.filter((row) => {
              return Object.keys(row).some((field) => {
                  // console.log(field)
                  // console.log(row)
                  return searchRegex.test(row[field]);
              });
          });
          setRows(filteredRows);
        };

        const handleAlumnoDetalle = (id) => {
          history.push('alumnos/detalle/' + id )
        }
        const [anchorElOpciones, setAnchorElOpciones] = React.useState(null);
        const open = Boolean(anchorElOpciones);
      
        const handleClickOpciones = (event) => {
          setAnchorElOpciones(event.currentTarget);
        };
      
        const handleCloseOpciones = () => {
          setAnchorElOpciones(null);
        };

        const handleExportarTelefonos = () => {
          setAnchorElOpciones(null);
        }
        const handleAlumnosExcel = () => {
          setAnchorElOpciones(null);
        }

        const [filterModel, onFilterModelChange] = useDataGridFilterPersist('alumnos_Seguimiento_page','alumnos_Seguimiento')

        return (
            rows ?
                <>
                    <Box mt={12} mb={3} mx={2}>
                        <Typography variant="h5" component="h3" gutterBottom display='inline' style={{fontWeight: 500}} >
                            {"Alumnos Seguimiento"}
                        </Typography>

                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={handleClickOpciones}
                        >
                          <MoreVertIcon />
                        </IconButton>

                        <Menu
                          id="long-menu"
                          anchorEl={anchorElOpciones}
                          keepMounted
                          open={open}
                          onClose={handleCloseOpciones}
                          
                        >
                        <MenuItem key="opcion1"  onClick={handleExportarTelefonos}>
                          <a 
                            href={`${BASE_URL_SERVICE}alumno/alumnos_exportar_correos`} 
                            rel='noreferrer'
                            target='_blank' 
                            style={{textDecoration: 'none'}}>Exportar correos electrónicos</a>
                        </MenuItem>
                        <MenuItem key="opcion1"  onClick={handleExportarTelefonos}>
                          <a 
                            href={`${BASE_URL_SERVICE}alumno/alumnos_exportar_telefonos`} 
                            rel='noreferrer'
                            target='_blank' 
                            style={{textDecoration: 'none'}}>Exportar teléfonos</a>
                        </MenuItem>
                        <MenuItem key="opcion1"  onClick={handleExportarTelefonos}>
                          <a 
                            href={`${BASE_URL_SERVICE}alumno/alumnos_exportar_telefonos_comunidad`} 
                            rel='noreferrer'
                            target='_blank' 
                            style={{textDecoration: 'none'}}>Exportar teléfonos por comunidad</a>
                        </MenuItem>
                        <MenuItem key="opcion1"  onClick={handleExportarTelefonos}>
                          <a 
                            href={`${BASE_URL_SERVICE}alumno/alumnos_exportar_telefonos_oposicion`} 
                            rel='noreferrer'
                            target='_blank' 
                            style={{textDecoration: 'none'}}>Exportar teléfonos por oposición</a>
                        </MenuItem>
                       
                        <MenuItem key="opcion1"  onClick={handleExportarTelefonos}>
                          <a 
                            href={`${BASE_URL_SERVICE}alumnos/verificar_excel`} 
                            rel='noreferrer'
                            target='_blank' 
                            style={{textDecoration: 'none'}}>Verificar EXCEL</a>
                        </MenuItem>

                        <MenuItem key="opcion1"  onClick={handleAlumnosExcel}>
                          <a 
                            href={`${BASE_URL_SERVICE}alumno/alumno_exportar_excel`} 
                            rel='noreferrer'
                            target='_blank' 
                            style={{textDecoration: 'none'}}>Exportar a excel</a>
                        </MenuItem>
                       
                      </Menu>
                    </Box>
                    
    
                    <div style={{ height: 500, width: '100%', padding:"0 15px" }}>
                        <DataGridPro
                          filterModel={ filterModel }
                          onFilterModelChange={ onFilterModelChange }
                          rows={rows} 
                          columns={columnas} 
                          rowHeight={30}
                          components={{ Toolbar: QuickSearchToolbar }}
                          onRowDoubleClick={ (evento ) => handleAlumnoDetalle(evento.id) }
                          componentsProps={{
                              toolbar: {
                                value: searchText,
                                onChange: (event) => requestSearch(event.target.value),
                                clearSearch: () => requestSearch(''),
                              },
                          }}
                        />
                    </div>

                    {/* <Typography variant='caption' style={{marginTop: 10, marginLeft: 16}}>NOTA: Ordenado por fecha de entrada de gasto decreciente</Typography> */}

                </>
                
                :
                <EmptyComponent mensaje="No hay datos para mostrar" />
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
