import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../redux/reducer/loginReducer";
import TituloPagina from '../../../components/Titulos/TituloPagina';
import { getCmiComerciales } from '../../../services/alumnos';
import { Box, Button, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Card, CardContent, Typography, Grid, Divider } from '@material-ui/core';
import { GaugeComponent } from 'react-gauge-component';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function CmiComercialContent() {
    const classes = useStyles();

    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")
    const [estadisticas, setEstadisticas] = useState([])
    const [estadisticasAntiguosAlumnos, setEstadisticasAntiguosAlumnos] = useState([])
    const [datosAgrupados, setDatosAgrupados] = useState([])

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando datos")

        const usuario_id = usuarioSeleccionado.id

        getCmiComerciales({ usuario_id }).then((response) => {
            setLoading(false)

            response && setEstadisticas([response.estadisticas])
            response && setEstadisticasAntiguosAlumnos([response.estadisticas_antiguos_alumnos])
        });

    }, [])

    useEffect(() => {

        setDatosAgrupados(

            estadisticas.map((estadistica) => {
              return estadistica
            })

        )

    }, [estadisticas])

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    const Contenido = () => {
        return (
        <>
            <TituloPagina titulo={"Principales indicadores de comerciales"} />

            <Box style={{paddingRight: 20, paddingLeft: 20}}>
            <Divider style={{marginBottom: 10}}/>
            <Button variant='outlined'  href="https://gestion.innovatiio.com/api/procesos/ringover_actualizacion_llamadas" target="_blank" rel="noreferrer" className={classes.enlace}>Sincronizar datos con Ringover ahora</Button>
            {
                Object.entries(datosAgrupados).map(([index, usuarios]) => {
                    return Object.entries(usuarios).map(([usuario_id, datos]) => {
                        const usuario_nombre = datos['estadisticas'][0]['usuario_nombre']
                        const datos_estadisticos = datos['estadisticas']
                        const objetivo_ventas_mes = datos['objetivo_ventas_mes']
                        const ventas_mes = datos['ventas_mes']
                        const datos_estadisticos_antiguos_alumnos = estadisticasAntiguosAlumnos[0].filter((item) => parseInt(item.comercial_usuario_id) === parseInt(usuario_id))

                        return (
                          <>
                            <h3>{`${usuario_nombre}`}</h3>
                            <Card style={{ marginBottom: '20px', width: 'fit-content' }}>
                              <CardContent>
                                <Grid container spacing={4} style={{margin: 0}}>
                                  <Grid item xs={7}>
                                    <Typography variant="h5" component="div" gutterBottom>
                                      Ventas del mes
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                      {ventas_mes.length}/{objetivo_ventas_mes}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                      <GaugeComponent 
                                        type="semicircle"
                                        arc={{
                                          width: 0.2,
                                          padding: 0.005,
                                          cornerRadius: 1,
                                          subArcs: [
                                            { limit: objetivo_ventas_mes * 0.5, color: '#ec7063' }, // Rojo pastel
                                            { limit: objetivo_ventas_mes * 0.75, color: '#f4d03f' }, // Amarillo pastel
                                            { limit: objetivo_ventas_mes, color: '#52be80' } // Verde pastel
                                          ]
                                        }}
                                        pointer={{
                                          color: '#345243',
                                          length: 0.60, // Ajusta la longitud del puntero
                                          width: 10, // Ajusta el ancho del puntero
                                          elastic: true,
                                          type: "arrow",
                                        }}
                                        labels={{
                                          valueLabel: { 
                                            formatTextValue: value => `${value} ventas` ,
                                            style: { textShadow: 'none', fill: 'black' } 
                                          },
                                          tickLabels: {
                                            type: 'outer',
                                            defaultTickValueConfig: { 
                                              formatTextValue: value => `${value}`,
                                              style: { fontSize: 7 } // Ajusta el tamaño de la fuente de las etiquetas
                                            },
                                            ticks: [
                                              { value: objetivo_ventas_mes * 0.5 },
                                              { value: objetivo_ventas_mes * 0.75 },
                                              { value: objetivo_ventas_mes }
                                            ],
                                          }
                                        }}
                                        value={ventas_mes.length}
                                        minValue={0}
                                        maxValue={objetivo_ventas_mes}
                                        style={{ width: '150px', height: '70px' }} // Ajusta el tamaño del GaugeComponent
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                            
                            
                            <TableContainer component={Paper} style={{width: 'fit-content' }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell></TableCell>
                                  {datos_estadisticos.map((item) => (
                                    <TableCell align="right">
                                      {item.fecha}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>Total minutos</TableCell>
                                  {datos_estadisticos.map((item) => (
                                    <TableCell align="right">
                                      { parseInt(item.total_minutos) > 0 ? parseInt(item.total_minutos) : "" }
                                    </TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  <TableCell>Total llamadas</TableCell>
                                  {datos_estadisticos.map((item) => (
                                    <TableCell align="right">
                                      { parseInt(item.total_llamadas) > 0 ? parseInt(item.total_llamadas) : "" }
                                    </TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  <TableCell>Llamadas menos 3 min</TableCell>
                                  {datos_estadisticos.map((item) => (
                                    <TableCell align="right">
                                      { parseInt(item.llamadas_menos_tres_minutos) > 0 ? parseInt(item.llamadas_menos_tres_minutos) : "" }
                                    </TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  <TableCell>Llamadas más 3 min</TableCell>
                                  {datos_estadisticos.map((item) => (
                                    <TableCell align="right">
                                      { parseInt(item.llamadas_mas_tres_minutos) > 0 ? parseInt(item.llamadas_mas_tres_minutos) : "" }
                                    </TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  <TableCell>Leads gestionados</TableCell>
                                  {datos_estadisticos.map((item) => (
                                    <TableCell align="right">
                                      { parseInt(item.leads_gestionados) > 0 ? parseInt(item.leads_gestionados) : "" }
                                    </TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  <TableCell>Leads quemados <span style={{color: "#a9a9a9"}}>(gestionados perdidos)</span></TableCell>
                                  {datos_estadisticos.map((item) => (
                                    <TableCell align="right">
                                      { parseInt(item.leads_quemados) > 0 ? parseInt(item.leads_quemados) : "" }
                                    </TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  <TableCell>Ventas</TableCell>
                                  {datos_estadisticos.map((item) => (
                                    <TableCell align="right">
                                      { parseInt(item.num_ventas) > 0 ? parseInt(item.num_ventas) : "" }
                                    </TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  <TableCell>Demos enviadas</TableCell>
                                  {datos_estadisticos.map((item) => (
                                    <TableCell align="right">
                                      { parseInt(item.num_demos_enviadas) > 0 ? parseInt(item.num_demos_enviadas) : "" }
                                    </TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  <TableCell>Enlaces de pago (no cobrados)</TableCell>
                                  {datos_estadisticos.map((item) => (
                                    <TableCell align="right">
                                      { parseInt(item.num_enlaces_enviados_no_cobrados) > 0 ? parseInt(item.num_enlaces_enviados_no_cobrados) : "" }
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableBody>
                            </TableContainer>

                            <Grid container spacing={4} style={{margin: 0}}>
                              <Grid item xs={3}>
                                <h3>Antiguos alumnos - Perdidos</h3>
                              </Grid>
                              <Grid item xs={3}>
                                <h3>Antiguos alumnos - Contactados</h3>
                              </Grid>
                              <Grid item xs={3}></Grid>
                              <Grid item xs={3}></Grid>

                              <Grid item xs={3}>
                                <TableContainer component={Paper} style={{width: 'fit-content' }}>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Motivo perdida</TableCell>
                                      <TableCell>Cantidad</TableCell>
                                      
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      { estadisticasAntiguosAlumnos.length > 0 &&
                                        datos_estadisticos_antiguos_alumnos.map((item) => {
                                          return <>
                                          { item.status_id === "96" && <TableRow>
                                            <TableCell>{item.motivo_perdida_nombre}</TableCell>
                                            <TableCell>{item.cantidad}</TableCell>
                                          </TableRow>}
                                          </>
                                        })
                                      } 
                                  </TableBody>
                                </TableContainer>
                              </Grid>

                              <Grid item xs={3}>
                                <TableContainer component={Paper} style={{width: 'fit-content' }}>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Estado</TableCell>
                                      <TableCell>Cantidad</TableCell>
                                      
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      { estadisticasAntiguosAlumnos.length > 0 &&
                                        datos_estadisticos_antiguos_alumnos.map((item) => {
                                          return <>
                                          { item.status_id !== "96" && <TableRow>
                                            <TableCell>{item.status_nombre}</TableCell>
                                            <TableCell>{item.cantidad}</TableCell>
                                          </TableRow>}
                                          </>
                                        })
                                      } 
                                  </TableBody>
                                </TableContainer>
                              </Grid>
                            </Grid>
                            
                          </>
                        );
                    })
                })
            }
            </Box>
        </>
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}

const ContenidoComercial = () => {
  return ( <>
  
  </> )
}

