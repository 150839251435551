import React, { useEffect } from "react";
import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import LoadingComponent from "../../../../components/Loading";

import { useSelector } from "react-redux";

import { selectUsuarioSeleccionado } from "../../../../redux/reducer/loginReducer";
import EmptyComponent from "../../../../components/EmptyContent";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  GridActionsCellItem,
} from "@mui/x-data-grid";
import EditIcon from "@material-ui/icons/Edit";
import { Link, useHistory } from "react-router-dom";
import TituloPagina from "../../../../components/Titulos/TituloPagina";
import AddIcon from "@material-ui/icons/Add";
import {
  getAlumnosAsignacionMasivaGestion,
  upcateComercialAsignacionMasiva,
} from "../../../../services/alumnos";
import { Stack } from "@mui/system";
import { getComerciales } from "../../../../services/comerciales";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import Swal from "sweetalert2";
import useDataGridFilterPersist from "../../../../hooks/useDataGridFilterPersist";
import DataGridInnovattio from "../../../../components/DataGridInnovatiio";
import { isEmptyArray } from "formik";
import { getListaDetalle } from "../../../../services/lista_detalle";

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "90vh",
  },
  emptyContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
    width: "100%",
    flexDirection: "column",
    textAlign: "center",
  },
  emptyImage: {
    width: "100%",
  },
  rootFilter: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  textFieldFilter: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    margin: theme.spacing(1, 0.5, 1.5),
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(0.5),
    },
    "& .MuiInput-underline:before": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  enlace: {
    color: theme.palette.link.main,
    textDecoration: "none",
  },
}));

export default function AlumnosAsignacionMasivaContent() {
  const classes = useStyles();

  const [Alumnos, setAlumnos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMensaje, setLoadingMensaje] = useState("");
  const [estados, setEstados] = useState(undefined);

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });
  const history = useHistory();

  const [comerciales, setComerciales] = useState(undefined);

  /* -------------------------------------------------------------------------- */
  /*                                  UseEffect                                 */
  /* -------------------------------------------------------------------------- */
  const fetchAlumnos = () => {
    setLoading(true);
    const usuario_id = usuarioSeleccionado.id;
    getAlumnosAsignacionMasivaGestion({ usuario_id }).then((response) => {
      console.log(response);
      response && setAlumnos(response.fichas_principales);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);

    const usuario_id = usuarioSeleccionado.id;

    fetchAlumnos();

    getListaDetalle({ usuario_id: usuario_id, lista_id: 8 }).then(
      (response) => {
        response.lista_detalle && setEstados(response.lista_detalle.filter(
          (item) => item.id === "88" || item.id === "95" || item.id === "231"
        ));
      }
    );
    
    getComerciales({ usuario_id: usuario_id }).then((response) => {
      if (response.comerciales) {
        const comercialesFiltrados = response.comerciales.filter(
          (item) => item.es_asignacion_automatica === "1"
        );
        // Añadir el nuevo comercial al array
        comercialesFiltrados.unshift({
          id: "1",
          nombre: "Quitar comercial",
          es_asignacion_automatica: "1",
        });
        setComerciales(comercialesFiltrados);
      }
    });
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                           Definicion de columnas                           */
  /* -------------------------------------------------------------------------- */

  const columnas = [
    { field: "id", hide: true, headerName: "ID" },
    { field: "nombre", headerName: "Nombre", minWidth: 150, flex: 1 },
    {
      field: "correo_electronico",
      headerName: "Correo electrónico",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "telefono",
      width: 150,
      headerName: "Teléfono",
      description: "Teléfono",
      renderCell: (cellValues) => {
        return (
          // <a className={classes.enlace} href={`tel:${cellValues.value}`}>
          <>{cellValues.value}</>
          // </a>
        );
      },
    },
    { field: "status_nombre", headerName: "Estado", minWidth: 120 },
    {
      field: "comercial_nombre",
      headerName: "Comercial",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "comunidad_autonoma_nombre",
      headerName: "Comunidad",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "oposicion_nombre",
      headerName: "Oposición",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "especialidad_nombre",
      headerName: "Especialidad",
      minWidth: 10,
      flex: 1,
    },
    {
      field: "ultima_interaccion",
      headerName: "Ultima interacción",
      minWidth: 10,
      flex: 1,
    },
    {
      field: "es_valido",
      headerName: "Valido",
      minWidth: 10,
      // type: "boolean",
      valueGetter: ( cellValues ) => cellValues.row.es_valido === "1" ? "SI" : "NO",
      renderCell: (cellValues) => {
        return (
          <>{cellValues.row.es_valido === "1" ? "SI" : "NO"}</>
        );
      },
    },
    {
      field: "num_comerciales",
      headerName: "Num. Comerciales",
      minWidth: 10,
      flex: 1,
      type: "number",
    },
    {
      field: "comerciales_asignados_nombre",
      headerName: "Comerciales asignados",
      minWidth: 10,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Modificar"
          component={Link}
          to={`alumnos/detalle/${params.id}`}
          showInMenu
        />,
        // <GridActionsCellItem
        //   icon={<DeleteIcon />}
        //   label="Eliminar"
        //   onClick={() => linkDelete(params.id)}
        //   showInMenu
        // />,
      ],
    },
  ];

  /* -------------------------------------------------------------------------- */
  /*                                  Contenido                                 */
  /* -------------------------------------------------------------------------- */
  const Contenido = ({fetchAlumnos,alumnos}) => {
    const apiRef = useGridApiRef();
    const [rows, setRows] = React.useState(alumnos);
    const [cambiarEstadoALead, setCambiarEstadoALead] = useState(false);
    const [comercialSeleccionado, setComercialSeleccionado] = React.useState([]);
    const [estadoSeleccionado, setEstadoSeleccionado] = React.useState([]);

    useEffect(() => {
      setRows(alumnos);
    }, [alumnos]);

    const handleAlumnoDetalle = (id) => {
      history.push("alumnos/detalle/" + id);
    };

    const handleAsignarComercial = () => {
      const selectedRows = Array.from(apiRef.current.getSelectedRows().keys());

      if(selectedRows.length === 0){
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Debes seleccionar al menos un alumno",
        });
        return;
      }
      
      if (isEmptyArray(comercialSeleccionado)) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Debes seleccionar un comercial",
        });
        return;
      }

      let usuario_id = usuarioSeleccionado.id
      upcateComercialAsignacionMasiva({
        usuario_id: usuario_id,
        alumnos_id: selectedRows,
        comercial_id: comercialSeleccionado,
        status_id: estadoSeleccionado
      }).then( (response) => {
        fetchAlumnos();
      })

      // Limpio la seleccion
      apiRef.current.setRowSelectionModel([]);
      
    }
    const [filterModel, onFilterModelChange] = useDataGridFilterPersist(
      "asignacion_masiva_alumnos_page",
      "alumnos"
    );

    return rows ? (
      <>
        <TituloPagina titulo={"Asignación masiva de Leads"} />

        <Grid xs={6}>
          <FormControl variant="outlined" fullWidth>
            <Stack direction="row" mb={2} mx={2} spacing={1}>
              <Grid xs={6}>
                <InputLabel style={{ paddingLeft: 20 }} id="comercial_id-label">
                  Comercial
                </InputLabel>
                <Select
                  labelId="comercial_id-label"
                  id="comercial_id"
                  name="comercial_id"
                  label="Comercial"
                  fullWidth
                  value={comercialSeleccionado}
                  onChange={(value) => {
                    setComercialSeleccionado(value.target.value);
                  }}
                  // error={formik.touched.comercial_id && Boolean(formik.errors.comercial_id)}
                >
                  {comerciales &&
                    comerciales.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nombre}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>

              <Grid xs={6}>
                <InputLabel style={{ paddingLeft: 20 }} id="estado_id-label">
                  Estado
                </InputLabel>
                <Select
                  labelId="estado_id-label"
                  id="estado_id"
                  name="estado_id"
                  label="Estado"
                  fullWidth
                  value={estadoSeleccionado}
                  onChange={(value) => {
                    setEstadoSeleccionado(value.target.value);
                  }}
                  // error={formik.touched.comercial_id && Boolean(formik.errors.comercial_id)}
                >
                  {estados &&
                    estados.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nombre}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
              
              <Grid xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  fullWidth
                   onClick={() => handleAsignarComercial()}
                   style={{
                    height: "100%",
                    padding: "10px",
                    fontSize: "14px",
                    textTransform: "none",}}
                >
                  Asignar comercial
                </Button>
              </Grid>
            </Stack>
          </FormControl>
        </Grid>


        <div style={{ height: 500, width: "100%", padding: "0 15px" }}>
          <DataGridInnovattio
            apiRef={apiRef}
            rows={rows}
            columns={columnas}
            rowHeight={30}
            onDoubleClick={(evento) => handleAlumnoDetalle(evento.id)}
            filterModel={filterModel}
            onFilterModelChange={onFilterModelChange}
            checkboxSelection={true}
          />
        </div>
      </>
    ) : (
      <EmptyComponent mensaje="No hay datos para mostrar" />
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={classes.container}>
      {loading ? (
        <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
      ) : (
        <Contenido 
          fetchAlumnos={fetchAlumnos}
          alumnos={Alumnos} 
        />
      )}
    </div>
  );
}