import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import * as yup from "yup";
import { useFormik, } from "formik";
import { transferirComercial, updateMotivoPerdida } from "../../../services/alumnos";
import { useSelector } from "react-redux";
import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
import {
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { getListaDetalle } from "../../../services/lista_detalle";
import { getComerciales } from "../../../services/comerciales";

const DialogModalTransferirComercial = ({ openModal, setOpenModal, alumnoId }) => {
  const history = useHistory();

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  const [comerciales, setComerciales] = useState([]);

  const [initialValues, setInitialValues] = useState({
    comercial_id: "",
  });
  const validationSchema = yup.object({
    comercial_id: yup.mixed().required("Obligatorio"),
  });

  useEffect(() => {
    const usuario_id = usuarioSeleccionado.id;

    // Comerciales
    getComerciales({ usuario_id: usuario_id }).then((response) => {
      // Solo me quedo con los comerciales activos
      response.comerciales && setComerciales(response.comerciales.filter(comercial => comercial.es_asignacion_automatica === "1"));
    });
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {

        values.alumnoId = alumnoId;

        transferirComercial({usuario_id: usuarioSeleccionado.id, alumno_id: alumnoId, comercial_id: values.comercial_id}).then( (response) => {
            history.push('/alumnos/detalle/' + alumnoId)
            history.go(0)
            setOpenModal(false);
        } )

    },
  });

  return (
    <Dialog open={openModal} onClose={() => setOpenModal(false)}>
      <DialogTitle>Selecciona el nuevo comercial</DialogTitle>
      <DialogContent style={{ minHeight: "150px", width: "600px" }}>
        <form
          noValidate
          method="POST"
          enctype="multipart/form-data"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Grid
            container
            spacing={2}
            style={{ flex: 1, width: "100%", paddingLeft: 15 }}
          >
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{}}
                error={
                  formik.touched.comercial_id &&
                  Boolean(formik.errors.comercial_id)
                }
              >
                <InputLabel id="comercial_id-label">
                  Comercial
                </InputLabel>
                <Select
                  labelId="comercial_id-label"
                  id="comercial_id"
                  name="comercial_id"
                  label="Comercial"
                  fullWidth
                  value={formik.values.comercial_id}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.comercial_id &&
                    Boolean(formik.errors.comercial_id)
                  }
                >
                  {comerciales &&
                    comerciales.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nombre}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {formik.touched.comercial_id &&
                    formik.errors.comercial_id}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" color="primary" onClick={() => setOpenModal(false)}>
                Cancelar
              </Button>
              <Button variant="contained" color="primary" type="submit" style={{marginLeft: 10}}>
                Asignar nuevo comercial
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default DialogModalTransferirComercial;
