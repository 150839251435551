import Login from './pages/login';
import Logout from './pages/logout';
import Home from './pages/home';

import NotFound from './pages/notFound';

import {
  BrowserRouter,
  Link,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import React from 'react';

import { useSelector } from "react-redux";

import {
  selectUsuarioSeleccionado,
} from "./redux/reducer/loginReducer";
import AlumnosListado from './pages/alumnos/alumnosListado';
import AlumnosForm from './pages/alumnos/alumnosForm/AlumnosForm';
import CuotasListado from './pages/cuotas/cuotasListado';
import AlumnosRegistradosListado from './pages/alumnos/alumnosRegistradosListado';
import AlumnosAsignacionMasivaListado from './pages/alumnos/alumnosListadoAsignacionMasiva';
import FacturasListado from './pages/cuotas/facturas';
import AnaliticaPivot from './pages/analitica/pivot';
import MSconta from './pages/contabilidad/msconta';
import AvisosListado from './pages/avisos/avisos';
import AlumnosBajaListado from './pages/alumnos/alumnosBajaListado';
import AlumnosRegistradosAndaluciaListado from './pages/alumnos/alumnosRegistradosAndaluciaListado';
import AccesosTuteaListado from './pages/alumnos/accesosTutea';
import BuscarAlumno from './pages/alumnos/buscarAlumno/BuscarAlumno';
import VisionCuotas from './pages/visionCuotas/VisionCuotas';
import LiquidacionTutores from './pages/liquidacionTutores';
import AlumnosPosibleBajaListado from './pages/alumnos/alumnosPosibleBajaListado';
import TutoresListado from './pages/tutores';
import AvisosSeguimientoListado from './pages/avisos/avisos/AvisosSeguimientoListado';
import TutoriasIndividualesListado from './pages/tutoriasIndividuales';
import AlumnosGestionAcademicaListado from './pages/alumnos_gestion_academica';
import AlumnosInteraccion from './pages/alumnos/alumnosInteraccion';
import FacturacionCreadoresListado from './pages/facturacionCreadores';
import RingoverLlamadasListado from './pages/ringover/llamadas';
import RingoverEstadisticasListado from './pages/cmi_comercial';
import AlumnosExposicionesOralesContent from './pages/alumnos/alumnosExposicionesOrales/components/AlumnosExposicionesOralesContent';
import AlumnosExposicionesOralesListado from './pages/alumnos/alumnosExposicionesOrales';
import TagsListado from './pages/tags';
import AlumnosNotasListado from './pages/alumnos/alumnosNotas';
import AlumnosProductosSeguimientoContent from './pages/alumnos/alumnosProductosSeguimiento/components/AlumnosProductosSeguimientoContent';
import AlumnosProductosSeguimientoListado from './pages/alumnos/alumnosProductosSeguimiento';
import PlanificacionLlamadasListado from './pages/planificacion_llamadas';
import SeguimientoTutoresListado from './pages/seguimientoTutores';
import AlumnosReservasListado from './pages/alumnos/alumnosReservaListado';
import AlumnosSeguimientoListado from './pages/alumnos/alumnosSeguimiento';

/* --------------------------------- Private -------------------------------- */
function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}

/* ---------------------------------- Home ---------------------------------- */
const RouteHome = () => {
  const usuarioSeleccionado = useSelector(state => {
    return selectUsuarioSeleccionado(state);
  });

  return (
    ( usuarioSeleccionado.perfil_id === "6" ?
      <Route component={AlumnosRegistradosAndaluciaListado} path="/home"></Route>
      :
      <Route component={Home} path="/home"></Route>
    )
  );
}


/* ------------------------------- Cuotas ------------------------------- */
const RouteCuotas = () => {
  return (
    <>
      <Route component={CuotasListado} path="/cuotas"></Route>
    </>
  );
}
/* ------------------------------- Cuotas ------------------------------- */
const RouteAnaliticaPivot = () => {
  return (
    <>
      <Route component={AnaliticaPivot} path="/analitica"></Route>
    </>
  );
}
/* ------------------------------- Contabilidad ------------------------------- */
const RouteMsconta = () => {
  return (
    <>
      <Route component={MSconta} path="/ms_conta"></Route>
    </>
  );
}
/* ------------------------------- Facturas ------------------------------- */
const RouteFacturas = () => {
  return (
    <>
      <Route component={FacturasListado} path="/facturas"></Route>
    </>
  );
}
/* ------------------------------- Avisos ------------------------------- */
const RouteAvisos = () => {
  return (
    <>
      <Route component={AvisosListado} path="/avisos"></Route>
    </>
  );
}
/* ------------------------------- Avisos seguimiento ------------------------------- */
const RouteAvisosSeguimiento = () => {
  return (
    <>
      <Route component={AvisosSeguimientoListado} path="/avisos_seguimiento"></Route>
    </>
  );
}
/* ------------------------------- Alumnos ------------------------------- */
const RouteAlumnos = () => {
  return (
    <>
      <Route component={AlumnosListado} path="/alumnos"></Route>
    </>
  );
}
const RouteAlumnosNotas = () => {
  return (
    <>
      <Route component={AlumnosNotasListado} path="/alumnos_notas"></Route>
    </>
  );
}
const RouteAlumnosProductosSeguimiento = () => {
  return (
    <>
      <Route component={AlumnosProductosSeguimientoListado} path="/alumnos_productos_seguimiento"></Route>
    </>
  );
}
const RouteAccesosTutea = () => {
  return (
    <>
      <Route component={AccesosTuteaListado} path="/accesos_tutea"></Route>
    </>
  );
}
const RouteAlumnosRegistrados = () => {
  return (
    <>
      <Route component={AlumnosRegistradosListado} path="/alumnos_registrados"></Route>
    </>
  );
}
const RouteAlumnosSeguimiento = () => {
  return (
    <>
      <Route component={AlumnosSeguimientoListado} path="/alumnos_seguimiento"></Route>
    </>
  );
}
const RouteAlumnosRegistradosAndalucia = () => {
  return (
    <>
      <Route component={AlumnosRegistradosAndaluciaListado} path="/alumnos_registrados_andalucia"></Route>
    </>
  );
}
const RouteAlumnosBaja = () => {
  return (
    <>
      <Route component={AlumnosBajaListado} path="/alumnos_baja"></Route>
    </>
  );
}
const RouteAlumnosPosibleBaja = () => {
  return (
    <>
      <Route component={AlumnosPosibleBajaListado} path="/alumnos_posible_baja"></Route>
    </>
  );
}
const RouteAlumnosAsignacionMasiva = () => {
  return (
    <>
      <Route component={AlumnosAsignacionMasivaListado} path="/alumnos_asignacion_masiva"></Route>
    </>
  );
}
const RouteAlumnosDetalle = () => {
  return (
    <>
      <Route component={AlumnosForm} path="/alumnos/detalle/:id"></Route>
    </>
  );
}
const RouteAlumnosCreate = () => {
  return (
    <>
      <Route component={AlumnosForm} path="/alumnos/create"></Route>
    </>
  );
}
const RouteBuscarAlumno = () => {
  return (
    <>
      <Route component={BuscarAlumno} path="/buscar_alumno"></Route>
    </>
  );
}
const RouteVisionCuotas = () => {
  return (
    <>
      <Route component={VisionCuotas} path="/vision_cuotas"></Route>
    </>
  );
}
const RouteLiquidacionTutores = () => {
  return (
    <>
      <Route component={LiquidacionTutores} path="/liquidacion_tutores"></Route>
    </>
  );
}
const RouteTutores = () => {
  return (
    <>
      <Route component={TutoresListado} path="/tutores"></Route>
    </>
  );
}
const RouteAlumnosGestionAcademica = () => {
  return (
    <>
      <Route component={AlumnosGestionAcademicaListado} path="/alumnos_gestion_academica"></Route>
    </>
  );
}
const RouteAlumnosInteraccion = () => {
  return (
    <>
      <Route component={AlumnosInteraccion} path="/alumnos_interaccion"></Route>
    </>
  );
}
const RouteTutoriasIndividuales = () => {
  return (
    <>
      <Route component={TutoriasIndividualesListado} path="/tutorias_individuales"></Route>
    </>
  );
}
const RouteFacturacion = () => {
  return (
    <>
      <Route component={FacturacionCreadoresListado} path="/facturacion"></Route>
    </>
  );
}
const RouteRingoverLlamadas = () => {
  return (
    <>
      <Route component={RingoverLlamadasListado} path="/ringover-llamadas"></Route>
    </>
  );
}
const RouteCmiComercial = () => {
  return (
    <>
      <Route component={RingoverEstadisticasListado} path="/cmi-comercial"></Route>
    </>
  );
}
const RouteExposicionesOrales = () => {
  return (
    <>
      <Route component={AlumnosExposicionesOralesListado} path="/exposiciones-orales"></Route>
    </>
  );
}
const RouteTags = () => {
  return (
    <>
      <Route component={TagsListado} path="/tags"></Route>
    </>
  );
}
const RoutePlanificacionLlamadas = () => {
  return (
    <>
      <Route component={PlanificacionLlamadasListado} path="/planificacion-llamadas"></Route>
    </>
  );
}
const RouteSeguimientoTutores = () => {
  return (
    <>
      <Route component={SeguimientoTutoresListado} path="/seguimiento-tutores"></Route>
    </>
  );
}
const RouteAlumnosReservas = () => {
  return (
    <>
      <Route component={AlumnosReservasListado} path="/alumnos_reservas"></Route>
    </>
  );
}


export default function App(props) {
  const usuarioSeleccionado = useSelector(state => {
    return selectUsuarioSeleccionado(state);
  });

  return (

    <BrowserRouter>
      <Switch>

        {/* ZONA PUBLICA */}
        <Route component={Login} path="/login"></Route>
        <Route component={Logout} path="/logout"></Route>
        <Route exact path="/">
          <div className="container">
            {
              usuarioSeleccionado ?
                <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
                :
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }
            <Link className="btn btn-secondary btn-lg btn-block" to="/login">Login</Link>
            <Link className="btn btn-secondary btn-lg btn-block" to="/home">Home</Link>
          </div>
        </Route>

        {/* ZONA PRIVADA */}
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/home' component={RouteHome} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos/create' component={RouteAlumnosCreate} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos/detalle/:id' component={RouteAlumnosDetalle} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos' component={RouteAlumnos} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_notas' component={RouteAlumnosNotas} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_productos_seguimiento' component={RouteAlumnosProductosSeguimiento} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/avisos' component={RouteAvisos} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/avisos_seguimiento' component={RouteAvisosSeguimiento} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/accesos_tutea' component={RouteAccesosTutea} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_registrados' component={RouteAlumnosRegistrados} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_seguimiento' component={RouteAlumnosSeguimiento} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_registrados_andalucia' component={RouteAlumnosRegistradosAndalucia} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_baja' component={RouteAlumnosBaja} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_posible_baja' component={RouteAlumnosPosibleBaja} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_asignacion_masiva' component={RouteAlumnosAsignacionMasiva} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/cuotas' component={RouteCuotas} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/facturas' component={RouteFacturas} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/analitica' component={RouteAnaliticaPivot} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/ms_conta' component={RouteMsconta} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/buscar_alumno' component={RouteBuscarAlumno} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/vision_cuotas' component={RouteVisionCuotas} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/liquidacion_tutores' component={RouteLiquidacionTutores} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/tutores' component={RouteTutores} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_gestion_academica' component={RouteAlumnosGestionAcademica} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_interaccion' component={RouteAlumnosInteraccion} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/tutorias_individuales' component={RouteTutoriasIndividuales} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/facturacion' component={RouteFacturacion} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/ringover-llamadas' component={RouteRingoverLlamadas} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/cmi-comercial' component={RouteCmiComercial} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/exposiciones-orales' component={RouteExposicionesOrales} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/tags' component={RouteTags} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/planificacion-llamadas' component={RoutePlanificacionLlamadas} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/seguimiento-tutores' component={RouteSeguimientoTutores} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_reservas' component={RouteAlumnosReservas} />
        {/* <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/envio/edit/:id' component={RouteEnvioEdit} /> */}

        {/* PAGINA NO ENCONTRADA */}
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>

  )
}