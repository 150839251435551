import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import * as yup from "yup";
import { useFormik, } from "formik";
import { useSelector } from "react-redux";
import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
import {
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { getListaDetalle } from "../../../services/lista_detalle";
import { getComerciales } from "../../../services/comerciales";
import { cambiarCorreoTelefono } from "../../../services/alumnos";

const DialogModalCambiarCorreoTelefono = ({ openModal, setOpenModal, alumnoId, correo_electronico, telefono }) => {
  const history = useHistory();

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  const [comerciales, setComerciales] = useState([]);

  const [initialValues, setInitialValues] = useState({
    correo_electronico: correo_electronico,
    telefono: telefono,
  });
  // const validationSchema = yup.object({
  //   comercial_id: yup.mixed().required("Obligatorio"),
  // });

  useEffect(() => {
    const usuario_id = usuarioSeleccionado.id;

    // Comerciales
    getComerciales({ usuario_id: usuario_id }).then((response) => {
      // Solo me quedo con los comerciales activos
      response.comerciales && setComerciales(response.comerciales.filter(comercial => comercial.es_asignacion_automatica === "1"));
    });
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: validationSchema,
    onSubmit: (values, actions) => {

        values.alumnoId = alumnoId;

        cambiarCorreoTelefono({usuario_id: usuarioSeleccionado.id, alumno_id: alumnoId, correo_electronico: values.correo_electronico, telefono: values.telefono}).then( (response) => {
            history.push('/alumnos/detalle/' + alumnoId)
            history.go(0)
            setOpenModal(false);
        } )

    },
  });

  return (
    <Dialog open={openModal} onClose={() => setOpenModal(false)}>
      <DialogTitle>Cambiar correo electronico/telefono</DialogTitle>
      <DialogContent style={{ minHeight: "150px", width: "600px" }}>
        <form
          noValidate
          method="POST"
          enctype="multipart/form-data"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Grid
            container
            spacing={2}
            style={{ flex: 1, width: "100%", paddingLeft: 15 }}
          >
            {/* Correo electronico */}
            <Grid item xs={12} md={12}>
              <TextField
                id="correo_electronico"
                name="correo_electronico"
                label="Correo electrónico"
                placeholder="Introduce el correo electrónico"
                fullWidth
                margin="normal"
                value={formik.values.correo_electronico}
                onChange={formik.handleChange}
                variant="outlined"
                error={
                  formik.touched.correo_electronico &&
                  Boolean(formik.errors.correo_electronico)
                }
                helperText={
                  formik.touched.correo_electronico &&
                  formik.errors.correo_electronico
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
      
            {/* Teléfono */}
            <Grid item xs={12} md={12}>
                <TextField
                  id="telefono"
                  name="telefono"
                  label="Teléfono"
                  placeholder="Introduce el teléfono"
                  fullWidth
                  margin="normal"
                  value={formik.values.telefono}
                  onChange={formik.handleChange}
                  variant="outlined"
                  error={
                    formik.touched.telefono && Boolean(formik.errors.telefono)
                  }
                  helperText={formik.touched.telefono && formik.errors.telefono}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" color="primary" onClick={() => setOpenModal(false)}>
                Cancelar
              </Button>
              <Button variant="contained" color="primary" type="submit" style={{marginLeft: 10}}>
                Cambiar datos
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default DialogModalCambiarCorreoTelefono;
