import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
import CmiComercialContent from './components/RingoverEstadisticasContent';

const CmiComercialListado = () => {

    return (
        <>

            <TemplateDashboard>
                <CmiComercialContent />
            </TemplateDashboard>

        </>
    )
};

export default CmiComercialListado;