import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import AlumnosSeguimientoContent from './components/AlumnosSeguimientoContent';

import {
    selectUsuarioSeleccionado,
} from "../../../redux/reducer/loginReducer";
import { useSelector } from 'react-redux';

const AlumnosSeguimientoListado = () => {
    return (
        <>

            <TemplateDashboard>
                { <AlumnosSeguimientoContent />}
            </TemplateDashboard>

        </>
    )
};

export default AlumnosSeguimientoListado;